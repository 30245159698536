<template>

    <div v-if="!isMobile" class="h-full flex items-center">
        <loader :loading="loading" />
        <div class="flex-1 overflow-hidden pr-4">
            <div class="bg-progress h-4 rounded-full overflow-hidden">
                <div class="h-full bg-danger rounded-full transition-size duration-500 ease-in-out"
                     :style="'width:' + effectivenessRatio + '%'">
                </div>
            </div>
        </div>
        <div class="flex-none w-32 text-right text-4xl font-semibold">{{ effectivenessRatio | numberFormat(2) }}%</div>
    </div>
    <div v-else class="h-20 rounded-lg bg-filters mt-2 flex flex-col">
        <div class="h-10 flex flex-row justify-between items-center px-4">
            <span class="text-font-gray font-semibold text-xs">Effectiveness ratio</span>
            <i class="mdi mdi-arrow-bottom-left text-danger"></i>
        </div>
        <div class="flex-1 flex flex-row">
            <div class="h-full w-9/12 flex flex-row justify-center items-center pl-4">
                <div class="h-2 w-full bg-body rounded-full overflow-hidden">
                    <div class="h-full bg-danger"
                         :style="'width:' + effectivenessRatio + '%; transition: all .3s;'">
                    </div>
                </div>
            </div>
            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-white font-semibold text-sm">
                    {{ effectivenessRatio | numberFormat(2) }}%
                </span>
            </div>
        </div>
    </div>

</template>

<script>
import { state } from '@/store';

export default {
    name: 'EffectivenessRatioDesktop',
    props: ['isMobile'],
    data() {
        return {
            effectivenessRatio: 0,
            loading: false
        }
    },
    methods: {
        preload() {
            this.loading = true;
        },
        load() {

            // if(state.rolSelected.role_type_link != 'presidencia'){

                this.axios.get('pipeline/effectiveness_ratio', {params: this.params}).then(response => {
                    this.effectivenessRatio = response.data.data.percentage
                })

            // }

            this.loading = false
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{sectorAmbiciona: this.$route.params.catId}}
            }
            return params
        },
    },
    watch:{
        params() { this.load() }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}

</script>